import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { SiWhatsapp } from "react-icons/si";
import { FaWhatsapp  } from "react-icons/fa";


const WhatsappButton = (props) => {
    const contactnNumber = '+971528448523';
    const url = "zeekay.ae/product/"+props.productURL;
    const message = `Hello, I'd like to inquire about the details of the: '${props.productName}'. SKU: '${props.productSKU}'. URL: ${url}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${contactnNumber}?text=${encodedMessage}`;
    return(
        <>
            {props.from === 'iconOnly'?
            <Link target="_blank" to={whatsappUrl}><Button><SiWhatsapp /></Button></Link>
            :<Link className='mr-2' target="_blank" to={whatsappUrl}><Button><FaWhatsapp /> <span className='pl-2'>Whatsapp</span></Button></Link>}
        </>
    )
}
export default WhatsappButton;