import { Link } from 'react-router-dom';
import logo from '../../assets/images/websitelogo.webp';
import HeaderSearch from './HeaderSearch';
import NavigationCategory from './NavigationCategory';
import NavigationMenu from './NavigationMenu';
import { useContext } from 'react';
import { cityContext } from '../../App';
import { TiThMenu } from "react-icons/ti";
import { BiSupport } from "react-icons/bi";
import FloatingNavigation from '../FloatingNavigation';

const Header = () =>{

    const context = useContext(cityContext);


    return (
        <>
            <div className="headerWrapper">
                <div className="top-strip bg-cream">
                    <div className="container">
                        <p className="mb-0 mt-0 text-center">
                            Same-Day Printing Available – <b>Order by 11 AM</b>
                        </p>
                    </div>
                </div>
                <header className='header bg-cream'>
                    <div className='container'>
                        <div className='row'>
                            <div className='logoWrapper d-flex align-items-center col-sm-2 mb-2 ml-0'>
                                <Link to ={'./'}><img src = {logo} alt='Site Logo '></img></Link>
                            </div>
                            <div className='col-sm-8 d-flex align-items-center custom-fields-wrapper'>
                                {/*{
                                    context.cityList.length!==0 && <CountryDropDown />
                                } */}
                                
                                <HeaderSearch />
                                {/*
                                <HeaderUser />
                                */}
                            </div>
                            <div className='col-sm-2'>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </header>
                <nav className='header bg-cream pb-2'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-3 navigation-category d-flex align-items-center'>
                                <NavigationCategory />
                            </div>
                            <div className='col-sm-9 navigation-menu d-flex align-items-center'>
                                <NavigationMenu />
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="mobileHeaderWrapper">
                <div className='MenuIcon col-sm-5'>
                    <Link onClick={()=>context.setmenuModalOpen(true)}><TiThMenu /></Link>
                </div>
                <div className='logoWrapper d-flex align-items-center col-sm-3'>
                    <Link to ={'./'}><img src = {logo} alt='Site Logo '></img></Link>
                </div>
                <div className='support col-sm-4'>
                    <div className='supportIcon'>
                        <BiSupport />
                    </div>
                    <div className='text'>
                        <p>Need Help ?</p>
                        <Link to='tel:+971528448523' target='_blank'>+971 52 844 8523</Link>
                    </div>
                </div>
                
            </div>
            <FloatingNavigation />
        </>
    )
}
export default Header;