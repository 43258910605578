import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineGppGood } from "react-icons/md";
import { Link } from 'react-router-dom';
import { RiCustomerService2Line } from "react-icons/ri";
import {TbTruckDelivery} from "react-icons/tb";
import { GiMoneyStack } from "react-icons/gi";
import axios from 'axios';
import {cityContext} from '../../App';
import { PulseLoader } from 'react-spinners';

const HomeAllCategory=()=>{

    const context = useContext(cityContext);
    const [loading, setloading] = useState(true);


    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    const [categories, setCategories] = useState([]);
    const [sliderKey, setSliderKey] = useState(0);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/category`);
                setCategories(response.data);
                setTimeout(() => setSliderKey(prevKey => prevKey + 1), 200);
                setloading(false);

            } catch (error) {
                console.error('Error fetching categories:', error);
                setloading(false);
            }
        };
        
        fetchCategories();
    }, [context.apiBaseUrl]);

    var categorySliderOptions = {
        dots:false,
        infinite:false,
        speed:500,
        slidesToShow:7,
        slidesToScroll:2,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                    initialSlide: 5,
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    arrows: true,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: true,
                }
            }
        ]
    }
    return(
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="services w-100 mt-2 mb-2 d-flex align-items-center justify-content-center">
                            <div className="serviceList d-flex align-item-center mr-4">
                                <RiCustomerService2Line/>
                                <span className="pl-2">Customer Service 24/7 Available</span>
                            </div>
                            <div className="serviceListSeparator d-flex align-items-stretch">
                                <span>&nbsp;</span>
                            </div>
                            <div className="serviceList d-flex align-items-center ml-4 mr-4">
                                <TbTruckDelivery/>
                                <span className="pl-2">Fast Delivery Service</span>
                            </div>
                            <div className="serviceListSeparator d-flex align-items-stretch">
                                <span>&nbsp;</span>
                            </div>
                            <div className="serviceList d-flex align-items-center ml-4 mr-4">
                                <MdOutlineGppGood/>
                                <span className="pl-2">100% Trusted by our Customers</span>
                            </div>
                            <div className="serviceListSeparator d-flex align-items-stretch">
                                <span>&nbsp;</span>
                            </div>
                            <div className="serviceList d-flex align-items-center ml-4 mr-4">
                                <GiMoneyStack/>
                                <span className="pl-2">Best Price on the Market</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="category_row_header w-100 mt-3 mb-2">
                                <h3>Featured Categories</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {loading?
                                <div className='loaderWrapper-homeAllCategory'>
                                    <PulseLoader color='#378087' loading={loading}/>
                                </div>:
                                <div className='category_row w-100'>
                                    <Slider {...categorySliderOptions} key={sliderKey}>
                                        {categories.filter((category) => category.category === null).map((category)=>(
                                            <div className='item categoryItem mt-2 mb-4' key={category.name}>
                                                <div className='imgWrapper'>
                                                    <Link to={`cat/${category.url}`}><img className='w-100' src={category.image}   alt={category.name} /></Link>
                                                </div>
                                                <div className='info pt-3'>
                                                    <Link to={`cat/${category.url}`}><h1>{category.name}</h1></Link>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAllCategory;