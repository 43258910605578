import { useContext, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/websitelogo.webp';
import Button from '@mui/material/Button';
import { AiOutlineClose } from 'react-icons/ai';
import { FormControlLabel } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import sidebanner2 from '../../assets/images/sidebanner2.png';
import { cityContext } from "../../App";

const SidebarFilterDrawer = ({ onCategoryChange, parentCategories, childCategories }) => {
    const context = useContext(cityContext);
    const [selectedCategory, setSelectedCategory] = useState('');

    const handleCheckboxChange = (categoryUrl) => {
        setSelectedCategory(categoryUrl);
        onCategoryChange(categoryUrl);
    };

    const renderCategories = (categories) => {
        return categories.map((category) => {
            const childCategoriesForParent = childCategories.filter(
                (childCategory) => childCategory.category._id.toString() === category._id.toString()
            );
            return (
                <li key={category._id}>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={selectedCategory === category.url}
                                onChange={() => handleCheckboxChange(category.url)}
                                onClick={() => context.setsidebarFilter(false)}
                            />
                        } 
                        label={category.name} 
                        className='w-100 checkbox' 
                    />
                    {childCategoriesForParent.length > 0 && (
                        <ul>
                            {childCategoriesForParent.map((childCategory) => (
                                <li key={childCategory._id} style={{ marginLeft: '20px' }}>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={selectedCategory === childCategory.url}
                                                onChange={() => handleCheckboxChange(childCategory.url)}
                                                onClick={() => context.setsidebarFilter(false)}
                                            />
                                        } 
                                        label={childCategory.name} 
                                        className='w-100 checkbox' 
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            );
        });
    };

    return(
        <>
            <Drawer open={true} className='mobileMenuDrawer'>
                <div className='menuDrawerInside' onClose={() => context.setsidebarFilter(false)}>
                    <div className='logoWrapper d-flex align-items-center col-sm-6'>
                        <Link to={'./'}><img src={logo} alt='Site Logo ' /></Link>
                    </div>
                    <Button className='drawerClose col-sm-6' onClick={() => context.setsidebarFilter(false)}>
                        <span className='drawerCloseIcon'>
                            <AiOutlineClose />
                        </span>        
                    </Button>
                </div>
                <div className="sidebarFilterDrawerInside">
                    <div className="filterBoxDrawer-checkboxes">
                        <h5 className="mt-3">Product Category</h5>
                        <div className="scroll">
                            <ul>
                                <li key='all-products'>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={selectedCategory === 'all-products'}
                                                onChange={() => handleCheckboxChange('all-products')}
                                                onClick={() => context.setsidebarFilter(false)}
                                            />
                                        } 
                                        label='All Products' 
                                        className='w-100 checkbox' 
                                    />
                                </li>
                                {renderCategories(parentCategories)}
                            </ul>
                        </div>
                    </div>
                    <h5 className="mt-5">Deals</h5>
                    <div className="filterBox mb-5">
                        <div className='banner'>
                            <img className="cursor" src={sidebanner2} alt='deal1' />
                        </div>
                    </div>                     
                </div>
            </Drawer>
        </>
    )
}

export default SidebarFilterDrawer;
