import siteLogo from '../../assets/images/websitelogo.webp';
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { CiFacebook, CiInstagram } from "react-icons/ci";
import shipworldwide from '../../assets/images/ship-worldwide.png';
import premiumquality from '../../assets/images/premiumquality.png';
import quicksupport from '../../assets/images/quicksupport.png';

const Footer =()=>{
    return(
        <footer>
            <div className="container">
                <div className="row footerInfo mb-3">
                    <div className="col-md-4 colOne">
                        <img src={siteLogo} alt='img'/>
                        <h3>Wasl District, Near Fish Roundabout, Deira</h3>
                        <p>zeekayadvertising@gmail.com</p>
                        <div className='d-flex  align-items-center'>
                            <Link>Get Direction</Link>
                            <FaArrowRightLong />
                        </div>
                        <div className='socialIcons d-flex align-items-center mt-3'>
                            <div className='item'>
                                <Link to='https://www.facebook.com/profile.php?id=61560195310434' target='_blank'><CiFacebook /></Link>
                            </div>
                            <div className='item'>
                                <Link to='https://www.instagram.com/zeekayprints/' target='_blank'><CiInstagram /></Link>
                            </div>
                        </div>                        
                    </div>
                    <div className='col-md-2 colTwo'>
                        <h3>Information</h3>
                        <ul>
                            <li><Link>Help Center</Link></li>
                            <li><Link>Shipping</Link></li>
                            <li><Link>Returns</Link></li>
                            <li><Link>Policies</Link></li>
                            <li><Link>Gift Cards</Link></li>
                        </ul>
                    </div>
                    <div className='col-md-2 colThree'>
                        <h3>Pages</h3>
                        <ul>
                            <li><Link>Location</Link></li>
                            <li><Link>Contact Form</Link></li>
                            <li><Link>Support</Link></li>
                            <li><Link>FAQs</Link></li>
                            <li><Link>Blog</Link></li>
                        </ul>
                    </div>
                    <div className='col-md-4 colFour'>
                        <h3>Services</h3>
                        <div className='colServices'>
                            <div className='services d-flex align-items-center justify-content-center mt-3'>
                                <div className='item pb-3   mr-3'>
                                    <img src={shipworldwide} alt='ss'/>
                                </div>
                                <div className='item'>
                                    <h3 className='mb-0'>Ship Worldwide</h3>
                                    <p>Enjoy secure global shipping on all orders, bringing your favorite products to your doorstep, no matter where you are!</p>
                                </div>
                            </div>
                            <div className='services d-flex align-items-center justify-content-center mt-3'>
                                <div className='item pb-3   mr-3'>
                                    <img src={premiumquality} alt='ss'/>
                                </div>
                                <div className='item'>
                                    <h3 className='mb-0'>Premium Quality</h3>
                                    <p>Crafted with the finest materials, our products guarantee exceptional quality and durability for a premium experience.</p>
                                </div>
                            </div>
                            <div className='services d-flex align-items-center justify-content-center mt-3'>
                                <div className='item pb-3   mr-3'>
                                    <img src={quicksupport} alt='ss'/>
                                </div>
                                <div className='item'>
                                    <h3 className='mb-0'>Quick Support</h3>
                                    <p>Get fast and friendly assistance whenever you need it—our support team is here to help you 24/7.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright pt-3 pb-3 mt-3 d-flex align-items-center justify-content-center">
                    <p>Copyright 2024 Zeekay Advertising. All rights reserved.</p>
                </div>
            </div>
        </ footer>
    )
}
export default Footer;