import Rating from '@mui/material/Rating';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WhatsappButton from '../WhatsappButton';
import PhoneButton from '../PhoneButton';

const ProductDetailDisplayData = (props) => {

    return(
        <>
            <div className='productDetailDisplayData'>
                <h1 className='productName'>{props.name}</h1>
                <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center border-right pr-3 productBrandName'>
                        <span>BRANDS: </span>
                        <span className='ml-2'>{props.brand}</span>
                    </div>
                    <div className='d-flex align-items-center border-right pr-3 pl-3 productRating'>
                        <Rating name='read-only' value={props.rating} readOnly size='small' precision={0.5} />
                        <span className='ml-2'>{props.numReviews} Reviews</span>
                    </div>
                    <div className='d-flex align-items-center pl-3 productSKU'>
                        <span>SKU: </span>
                        <span className='ml-2'>{props.sku}</span>
                    </div>
                </div>
                <p className='shortDescription mt-4' dangerouslySetInnerHTML={{ __html: props.shortDescription }}></p>
                <h4>Product Specifications</h4>
                <div className='mt-3 d-flex attributesData'>
                    <TableContainer >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Attribute</TableCell>
                                    <TableCell>Variation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.attributes.map((row, index)=>(
                                    <TableRow key={index}>
                                        <TableCell>{row.attributeName}</TableCell>
                                        <TableCell>{row.variations}</TableCell>
                                    </TableRow>
                                ))}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {/*<div className='mt-3 addToCartBox d-flex align-items-center'>
                    <Link><Button onClick={()=>{productQuantity!==1? setProductQuantity(productQuantity -1): setProductQuantity(productQuantity)}}><AiOutlineMinus /></Button></Link>
                    <p className='ml-3 mb-0 quantity'>{productQuantity}</p>
                    <Link className='ml-3'><Button onClick={()=>setProductQuantity(productQuantity+1)}><AiOutlinePlus /></Button></Link>
                    <Link className='ml-4'><Button className='addToCartBtn'>Add to Cart</Button></Link>
                </div>*/}
                <hr />
                <div className='icons mt-3 d-flex align-items-center mb-0'>
                    <h3 className='mr-3'>Contact: </h3>
                    <WhatsappButton 
                        from='modal'
                        productName = {props.name}
                        productSKU = {props.sku}
                        productURL = {props.url}
                    />
                    <PhoneButton 
                        from='modal'
                    />
                </div>
            </div>
        </>
    )
}
export default ProductDetailDisplayData;