
const NavigationMenu=()=>{
    return(
        <>
            {/*<ul className="list list-inline ml-auto">
                <li className="list-inline-item"><Link to="/"><Button>Home</Button></Link>
                    <div className="submenu shadow mt-2">
                        <Link to="/"><Button>Clothing</Button></Link>
                        <Link to="/"><Button>Footwear Branded by Sketchers</Button></Link>
                        <Link to="/"><Button>Watches</Button></Link>
                    </div>
                </li>
                <li className="list-inline-item"><Link to="/"><Button>Men</Button></Link>
                    <div className="submenu shadow mt-2">
                        <Link to="/"><Button>Clothing</Button></Link>
                        <Link to="/"><Button>Footwear Branded by Sketchers</Button></Link>
                        <Link to="/"><Button>Watches</Button></Link>
                    </div>
                </li>
                <li className="list-inline-item"><Link to="/"><Button>Women</Button></Link></li>
                <li className="list-inline-item"><Link to="/"><Button>Watches</Button></Link></li>
                <li className="list-inline-item"><Link to="/"><Button>Kids</Button></Link></li>
                <li className="list-inline-item"><Link to="/"><Button>Homestop</Button></Link></li>
                <li className="list-inline-item"><Link to="/"><Button>Gifts</Button></Link></li>
                <li className="list-inline-item"><Link to="/"><Button>Brands</Button></Link></li>
            </ul>*/}
        </>
    )
}
export default NavigationMenu;