import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { FaAngleRight } from "react-icons/fa6";
import React, { useEffect, useState, useContext } from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import ProductItem from '../ProductItem';
import axios from 'axios';
import {cityContext} from '../../App';
import { PulseLoader } from 'react-spinners';

const HomeNewproducts = () => {

    const context = useContext(cityContext);
    const [loading, setloading] = useState(true);
    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };

    var productSliderOptions = {
        dots: false,
        rows: 2,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: true,
                }
            }
        ]
    };
    
    const [latestProducts, setLatestProducts] = useState([]);
    const [sliderKey, setSliderKey] = useState(0);

    useEffect(() => {
        const fetchLatestProducts = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/product/latest`);
                setLatestProducts(response.data);
                
                // Ensure equal heights after the products are loaded
                //setTimeout(setEqualHeights, 0);
                
                // Force a re-render by changing the key
                setTimeout(() => setSliderKey(prevKey => prevKey + 1), 200);
                setloading(false);
            } catch (error) {
                console.error('Error fetching Featured Products:', error);
                setloading(false);
            }
        };

        fetchLatestProducts();
        
       // window.addEventListener('resize', setEqualHeights); // Call on window resize

        return () => {
           // window.removeEventListener('resize', setEqualHeights);
        };
    }, [context.apiBaseUrl]);
    
    /*function setEqualHeights() {
        const containers = document.querySelectorAll('.productItem .info h4');
        let maxHeight = 0;
    
        // Reset heights
        containers.forEach((container) => {
            container.style.height = 'auto';
        });
    
        // Find the max height
        containers.forEach((container) => {
            if (container.offsetHeight > maxHeight) {
                maxHeight = container.offsetHeight;
            }
        });
    
        // Set all containers to the max height
        containers.forEach((container) => {
            container.style.height = `${maxHeight}px`;
        });
    } */

    return (
        <div className='col-md-12 homeNewProducts mt-4'>
            <div className='d-flex align-items-center'>
                <div className='info'>
                    <h3 className='mb-0'>NEW ARRIVALS</h3>
                    <p className='text-light mb-0'>Do not miss the current offers until the end of March</p>
                </div>
                <div className='moreProducts ml-auto'>
                    <Link to='/cat/all-products'><Button>View All<FaAngleRight className='ml-auto pl-2 pb-1'/></Button></Link>
                </div>
            </div>
            <div className='product_row w-100 mt-4'>
                {loading?
                    <div className='loaderWrapper-homeAllCategory'>
                        <PulseLoader color='#378087' loading={loading}/>
                    </div>:
                    <Slider {...productSliderOptions} key={sliderKey}>
                        {latestProducts.map((product) => (
                            <ProductItem 
                                key={product._id}
                                name={product.name}
                                shortDescription={product.shortDescription}
                                title={product.title}
                                url={product.url}
                                metaKeywords={product.metaKeywords}
                                sku={product.sku}
                                featuredImage={product.featuredImage}
                                gallery={product.gallery}
                                rating={product.rating}
                                numReviews={product.numReviews}
                                brand={product.brand}
                            />
                        ))}
                    </Slider>
                }
            </div>
        </div>
    );
};

export default HomeNewproducts;
