import { FaAngleRight } from "react-icons/fa6";
import ProductImageContainer from '../../Components/ProductImageContainer';
import { TbCurrencyDirham } from "react-icons/tb";
import { MdOutlineDeliveryDining, MdOutlineGppGood } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { Link, useParams } from 'react-router-dom';
import ProductDetailDisplayData from '../../Components/ProductDetailDisplayData';
import ProductDetailTabs from "../../Components/ProductDetailTabs";
import ProductItem from "../../Components/ProductItem";
import Slider from "react-slick";
import { useEffect, useState, useContext } from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { cityContext } from "../../App";
import { PulseLoader } from 'react-spinners';


const ProductDetails = () => {

    const context = useContext(cityContext);

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [relatedProducts, setrelatedProducts] = useState([]);
    const [isloading, setIsloading] = useState('true');
    const [sliderKey, setSliderKey] = useState(0);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/product/productDetail/${id}`);
                setProduct(response.data[0]);
                document.title = `${response.data[0].name} - Creative Prints`;
                document.querySelector('meta[name="description"]').setAttribute("content", response.data[0].seoDescription);
                document.querySelector('meta[name="keywords"]').setAttribute("content", response.data[0].metaKeywords);
                setIsloading('false');
            } catch (error) {
                console.error('Error fetching product:', error);
                setIsloading('false');
            }
        };
        const fetchRelatedProduct = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/product/relatedProducts/${id}`);
                setrelatedProducts(response.data);
                setTimeout(() => setSliderKey(prevKey => prevKey + 1), 200);
                setIsloading('false');
            } catch (error) {
                console.error('Error fetching Related products:', error);
                setIsloading('false');
            }
        };
        
        fetchRelatedProduct();
        fetchProduct();

        window.scrollTo(0, 0);  
        setEqualHeights();
        window.addEventListener('resize', setEqualHeights);

        return () => {
            window.removeEventListener('resize', setEqualHeights);
        };
    }, [id, context.apiBaseUrl]);

    function setEqualHeights() {
        const containers = document.querySelectorAll('.productItem .info h4');
        let maxHeight = 0;

        containers.forEach((container) => {
            container.style.height = 'auto';
        });

        containers.forEach((container) => {
            if (container.offsetHeight > maxHeight) {
                maxHeight = container.offsetHeight;
            }
        });

        containers.forEach((container) => {
            container.style.height = `${maxHeight}px`;
        });
    }

    var productSliderOptions = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: true,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: true,
                }
            }
        ]
    };

    if (isloading === 'true') {
        return <div className='loaderWrapper-productList'>
        <PulseLoader color='#378087' isloading={isloading}/>
    </div>;
    }

    return (
        <>
            <div className="productDetail">
                <div className="w-100 productDetailsBanner">
                    <Link to="/shop"><h1>Shop</h1></Link>
                    <p className="d-flex align-items-center">
                        <Link to="/">Home</Link>
                        <FaAngleRight className="ml-2 mr-2" />
                        <Link to={`/cat/${product.category?.url}`}>{product.category?.name}</Link>
                        <FaAngleRight className="ml-2 mr-2" />
                        <span>{product.name}</span>
                    </p>
                </div>
                <div className='container w-75 mt-4'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <ProductImageContainer 
                                featuredImage={product.featuredImage}
                                galleryImages={product.gallery}
                            />
                        </div>
                        <div className='col-md-8 d-flex'>
                            <div className='leftSide'>
                                <ProductDetailDisplayData 
                                    name={product.name}
                                    brand={product.brand}
                                    rating={product.rating}
                                    numReviews={product.numReviews}
                                    sku={product.sku}
                                    shortDescription={product.shortDescription}
                                    attributes={product.attributes}
                                    url={product.url}
                                />
                            </div>
                            <div className='rightSide'>
                                <div className='ourFeatures d-flex'>
                                    <ul>
                                        <li>
                                            <div className='icon'>
                                                <MdOutlineDeliveryDining />
                                            </div>
                                            <div className='message'>
                                                Free Shipping apply to all orders over 2000 <TbCurrencyDirham />
                                            </div>
                                        </li>
                                        <li className='mt-4'>
                                            <div className='icon'>
                                                <MdOutlineGppGood />
                                            </div>
                                            <div className='message'>
                                                Quality Printing that's to your satisfaction.
                                            </div>
                                        </li>
                                        <li className='mt-4'>
                                            <div className='icon'>
                                                <IoWalletOutline />
                                            </div>
                                            <div className='message'>
                                                Our Printing Services are easy on your Wallet.
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container w-75 mt-4 mb-4'>
                    <ProductDetailTabs 
                        longDescription={product.longDescription}
                        attributes={product.attributes}
                        rating={product.rating}
                        numReviews={product.numReviews}
                        name={product.name}
                    />
                </div>
                <div className='container w-75 mt-4 mb-4'>
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="relatedProductsHeading">Related Products</h3>
                            <div className='product_row w-100 mt-4'>
                                <Slider {...productSliderOptions} key={sliderKey}>
                                    {relatedProducts.map((product) => (
                                        <ProductItem 
                                            key={product._id}
                                            name={product.name}
                                            shortDescription={product.shortDescription}
                                            title={product.title}
                                            url={product.url}
                                            metaKeywords={product.metaKeywords}
                                            sku={product.sku}
                                            featuredImage={product.featuredImage}
                                            gallery={product.gallery}
                                            rating={product.rating}
                                            numReviews={product.numReviews}
                                            brand={product.brand}
                                        />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;
