import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { AiOutlineFullscreen } from "react-icons/ai";
import Rating from "@mui/material/Rating";
import { cityContext } from "../../App";
import React, { useContext, useEffect, useState } from "react";
import WhatsappButton from '../WhatsappButton';
import PhoneButton from '../PhoneButton';

const ProductItem = (props) => {
    const context = useContext(cityContext);
    const [maxLength, setMaxLength] = useState(100);

    const viewProductDetails = (product) => {
        context.setisOpenProductModal(true);
        context.setSelectedProduct(product);
    }

    const truncateDescription = (text = '', maxLength) => {
        if (text.length > maxLength) {
            return `${text.slice(0, maxLength)}... `;
        }
        return text;
    };

    useEffect(() => {
        const updateMaxLength = () => {
            if (window.innerWidth < 480) {
                setMaxLength(60); // small screen
            } else if (window.innerWidth < 768) {
                setMaxLength(60); // medium screen
            } else if (window.innerWidth < 1280){
                setMaxLength(60);
            }else if (window.innerWidth < 1360){
                setMaxLength(60);
            }else if (window.innerWidth < 1600){
                setMaxLength(70);
            }else {
                setMaxLength(100); // large screen
            }
        };

        updateMaxLength();
        window.addEventListener('resize', updateMaxLength);

        return () => {
            window.removeEventListener('resize', updateMaxLength);
        };
    }, []);

    return (
        <>
            <div className={`productItemOuterDive ${props.itemView}`}>
                <div className={`item productItem`}>
                    <div className='imgWrapper'>
                        <img className='w-100' src={props.featuredImage} alt={props.metaKeywords} />
                    </div>
                    <div className='info'>
                        <h4>
                            <Link to={`/product/${encodeURIComponent(props.url)}`} style={{ textDecoration: 'unset', color: 'unset' }}>
                                {props.name}
                            </Link>
                        </h4>
                        <div className='actions'>
                            <Link to="#">
                                <Button onClick={() => viewProductDetails(props)}>
                                    <AiOutlineFullscreen />
                                </Button>
                            </Link>
                            <WhatsappButton
                                from='iconOnly'
                                productName={props.name}
                                productSKU={props.sku}
                                productURL={props.url}
                            />
                            <PhoneButton from='iconOnly' />
                        </div>
                        <span className='text-success d-block'>In Stock</span>
                        <Rating className='mt-2 mb-2' name='read-only' value={props.rating} readOnly size='small' precision={0.5} />
                        <div className='price align-items-center'>
                            <span dangerouslySetInnerHTML={{ __html: truncateDescription(props.shortDescription, maxLength) }} />
                            <Link to={`/product/${encodeURIComponent(props.url)}`}>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductItem;
