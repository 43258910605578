import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Components/Header";
import Footer from './Components/Footer';
import { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import ProductModal from "./Components/ProductModal";
import Listing from "./Pages/Listing";
import ProductDetails from "./Pages/ProductDetails";
import MobileMenuDrawer from "./Components/Header/MobileMenuDrawer";

const cityContext = createContext();

function App() {
  const [isOpenProductModal, setisOpenProductModal] = useState(false);
  const [menuModalOpen, setmenuModalOpen] = useState(false);
  const [sidebarFilter, setsidebarFilter] = useState(false);
  const [cityList, setcityList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  
  const [apiBaseUrl, setapiBaseUrl] = useState('https://printing-shop.onrender.com');

  const getCity = async (url) => {
    try {
      const response = await axios.get(url);
      setcityList(response.data.cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/category`);
      const parents = [];
      const children = [];
      response.data.forEach((category) => {
        if (category.category === null) {
          parents.push(category);
        } else {
          children.push(category);
        }
      });
      setParentCategories(parents);
      setChildCategories(children);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  },[apiBaseUrl]);
  useEffect(() => {
    getCity('../uaecities.json');
    fetchCategories();
  }, [fetchCategories]);

  const values = {
    cityList,
    isOpenProductModal,
    menuModalOpen,
    sidebarFilter,
    selectedProduct,
    parentCategories,
    childCategories,
    apiBaseUrl,
    setisOpenProductModal,
    setmenuModalOpen,
    setsidebarFilter,
    setSelectedProduct,
    setapiBaseUrl,
  };

  return (
    <BrowserRouter>
      <cityContext.Provider value={values}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cat/:categoryUrl" exact={true} element={<Listing />} />
          <Route path="/product/:id" exact={true} element={<ProductDetails />} />
        </Routes>
        <Footer />
        {isOpenProductModal && <ProductModal />}
        {menuModalOpen && <MobileMenuDrawer />}
        {/*sidebarFilter === true && <SidebarFilterDrawer />*/}
      </cityContext.Provider>
    </BrowserRouter>
  );
}

export default App;
export { cityContext };
