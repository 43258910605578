import React from "react";
import Slider from "react-slick";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

const HomeBanner = ()=>{
    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        autoplay: true,
        
    };

    return(
        <div className="homeBannerSection">
            <Slider {...settings}>
                <div className="item">
                    <img src="https://sslimages.shoppersstop.com/sys-master/root/h8e/hb2/33109669904414/Main_EOSS-FLAT-50-WEB--2024-07--10-new-hp.jpg" className="w-100" alt="banner1"/>
                </div>
                <div className="item">
                    <img src="https://sslimages.shoppersstop.com/sys-master/root/h7a/h30/33182085316638/SS24-web_3u0--ej9.jpg" className="w-100" alt="banner2"/>
                </div>
                <div className="item">
                    <img src="https://sslimages.shoppersstop.com/sys-master/root/h2a/hc6/33109669380126/women-westernwear_top-banner-web------flat-503off-2024-07-11.jpg" className="w-100" alt="banner3"/>
                </div>
                <div className="item">
                    <img src="https://sslimages.shoppersstop.com/sys-master/root/hd6/h55/33081764675614/watches_top-banner-web--new-home-page-main-2024-07-08.jpg" className="w-100" alt="banner4"/>
                </div>
            </Slider>
        </div>
    )
}
export default HomeBanner;