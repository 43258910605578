import { MdOutlineMailOutline } from "react-icons/md";
import Button from '@mui/material/Button';
<MdOutlineMailOutline />

const HomeNewsletter =()=>{
    return(
        <>
            <section className="newsLetterSection mt-3 mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="text-white">20 aed Discount for your First Order.</p>
                            <h4 className="text-white">Join our newsletter and get...</h4>
                            <p className="text-light">Join our email subsription now to get updates on promotions and coupons.</p>
                            <div className="emailField d-flex align-items-center pt-1 pb-1">
                                <MdOutlineMailOutline className="emailFieldIcon ml-3"/>
                                <input className="ml-3 w-50 emailFieldInput" type="text" placeholder='Email Address'/>
                                <Button className="ml-auto mr-1"> Subscribe</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default HomeNewsletter;