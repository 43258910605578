import React, { useContext, useState, useEffect } from "react";
import axios from 'axios';
import Slider from "react-slick";
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { AiOutlineFullscreen } from "react-icons/ai";
import WhatsappButton from "../WhatsappButton";
import PhoneButton from "../PhoneButton";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { cityContext } from "../../App";

const HomeExploreallproducts = () => {
    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };

    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };

    const ProductSliderOptionsPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <GoArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };

    const ProductSliderOptionsNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <GoArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };

    const categorySliderOptions = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: true,
                }
            }
        ]
    };

    const productSliderOptions = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <ProductSliderOptionsNextArrow />,
        prevArrow: <ProductSliderOptionsPrevArrow />,
        rows: 4
    };

    const context = useContext(cityContext);
    const viewProductDetails = (product) => {
        context.setisOpenProductModal(true);
        context.setSelectedProduct(product);
        console.log(product);
    };

    const [parentCategoriesWithProducts, setParentCategoriesWithProducts] = useState([]);
    const [sliderKey, setSliderKey] = useState(0);

    useEffect(() => {
        const fetchParentCategoriesWithProducts = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/product/parentCategoriesWithProducts`);
                setParentCategoriesWithProducts(response.data);

                // Trigger re-render of the slider
                setTimeout(() => setSliderKey(prevKey => prevKey + 1), 200);
            } catch (error) {
                console.error('Error fetching Parent Categories and Featured Products:', error);
            }
        };

        fetchParentCategoriesWithProducts();
    }, [context.apiBaseUrl]);

    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="category_row_header w-100 mt-3 mb-2">
                                <h3>Explore Featured Products Category-wise</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="categories w-100 mt-4">
                                <Slider {...categorySliderOptions} key={sliderKey}>
                                    {parentCategoriesWithProducts.map((categoryGroup, index) => (
                                        <div className='item categoryItem mt-2 mb-4 pr-3' key={index}>
                                            <h1>{categoryGroup.parentCategory.name}</h1>
                                            <Slider {...productSliderOptions}>
                                                {categoryGroup.products.map((product) => (
                                                    <div className="item productSliderItem mt-2 mb-2" key={product._id}>
                                                        <div className="productSliderItemContainer w-100">
                                                            <div className='imgWrapper'>
                                                                <img className='w-100' src={product.featuredImage} alt={product.name} />
                                                            </div>
                                                            <div className="info">
                                                                <h3>{product.category?.name || 'Category'}</h3>
                                                                <Link to={`/product/${encodeURIComponent(product.url)}`}>{product.name}</Link>
                                                                <div className="action">
                                                                    <Link to="#"><Button onClick={() => viewProductDetails(product)}><AiOutlineFullscreen /></Button></Link>
                                                                    <WhatsappButton
                                                                        from='iconOnly'
                                                                        productName={product.name}
                                                                        productSKU={product.sku}
                                                                        productURL={product.url}
                                                                    />
                                                                    <PhoneButton from='iconOnly' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeExploreallproducts;
