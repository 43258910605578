import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { RiMenu2Line } from "react-icons/ri";
import { FaAngleDown } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import axios from 'axios';
import {cityContext} from '../../../App';


const NavigationCategory=()=>{

    const context = useContext(cityContext);
    
    const [parentCategories, setParentCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/category`);
                const parents = [];
                const children = [];
                response.data.forEach((category) => {
                    if (category.category === null) {
                        parents.push(category);
                    } else {
                        children.push(category);
                    }
                });
                
                setParentCategories(parents);
                setChildCategories(children);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        
        fetchCategories();
    }, [context.apiBaseUrl]);
    const renderCategories = (categories) => {
        return categories.map((category) => {
            // Find children of the current parent category
            const childCategoriesForParent = childCategories.filter(
                (childCategory) => childCategory.category._id.toString() === category._id.toString()
            );
            return (
                <li key={category._id}>
                    <Link to={`cat/${category.url}`}>
                        <Button>
                            {category.name}
                            {childCategoriesForParent.length > 0 && (
                                <MdOutlineKeyboardArrowRight className="ml-auto" />
                            )}
                        </Button>
                    </Link>

                    {/* Only render the submenu if there are child categories */}
                    {childCategoriesForParent.length > 0 && (
                        <div className="sidemenu shadow">
                            <ul>
                                {childCategoriesForParent.map((childCategory) => (
                                    <li key={childCategory._id}>
                                        <Link to={`cat/${childCategory.url}`}>
                                            <Button>{childCategory.name}</Button>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </li>
            );
        });
    };
    
    return(
        <>
            <div className='categoryWrapper'>
                <Button className='allCatTab w-60'>
                    <span className='menuIcon mr-3'><RiMenu2Line className='pb-1' /></span>
                    <span className='text'>All CATEGORIES</span>
                    <span className='dropDownIcon ml-3'><FaAngleDown className='pb-1' /></span>
                </Button>
                <div className="sidebarNavigation mt-2 shadow">
                    <ul>
                        {renderCategories(parentCategories)}
                    </ul>
                </div>
            </div>
        </>
    )
}
export default NavigationCategory;