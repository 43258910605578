import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'react-range-slider-input/dist/style.css';
import { useState, useEffect, useContext } from 'react';
import sidebanner2 from '../../assets/images/sidebanner2.png';
import axios from 'axios';
import {cityContext} from '../../App';

const Sidebar = ({ onCategoryChange }) => {
    const context = useContext(cityContext);
    const [parentCategories, setParentCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/category`);
                const parents = [];
                const children = [];
                response.data.forEach((category) => {
                    if (category.category === null) {
                        parents.push(category);
                    } else {
                        children.push(category);
                    }
                });
                
                setParentCategories(parents);
                setChildCategories(children);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        
        fetchCategories();
    }, [context.apiBaseUrl]);

    const handleCheckboxChange = (categoryUrl) => {
        setSelectedCategory(categoryUrl);
        onCategoryChange(categoryUrl);
    };

    const renderCategories = (categories) => {
        return categories.map((category) => {
            const childCategoriesForParent = childCategories.filter(
                (childCategory) => childCategory.category._id.toString() === category._id.toString()
            );
            return (
                <li key={category._id}>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={selectedCategory === category.url}
                                onChange={() => handleCheckboxChange(category.url)}
                            />
                        } 
                        label={category.name} 
                        className='w-100 checkbox' 
                    />
                    {childCategoriesForParent.length > 0 && (
                        <ul>
                            {childCategoriesForParent.map((childCategory) => (
                                <li key={childCategory._id} style={{ marginLeft: '20px' }}>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={selectedCategory === childCategory.url}
                                                onChange={() => handleCheckboxChange(childCategory.url)}
                                            />
                                        } 
                                        label={childCategory.name} 
                                        className='w-100 checkbox' 
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            );
        });
    };

    return(
        <div className="sidebar">
            <h5>Product Category</h5>
            <div className="filterBox">
                <div className='scroll'>
                    <ul>
                        <li key='all-products'>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={selectedCategory === 'all-products'}
                                        onChange={() => handleCheckboxChange('all-products')}
                                    />
                                } 
                                label='All Products' 
                                className='w-100 checkbox' 
                            />
                        </li>
                        {renderCategories(parentCategories)}
                    </ul>
                </div>
            </div>
            <h5>Deals</h5>
            <div className="filterBox">
                <div className='banner'>
                    <img className="cursor" src={sidebanner2} alt='deal1'/>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
