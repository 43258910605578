import Slider from "react-slick";
import InnerImageZoom from 'react-inner-image-zoom';
import {useRef} from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

const ProductImageContainer = (imageProps) =>{
    const settingsRef = useRef(null);
    const thumbnailSettingsRef = useRef(null);
    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };

    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        asNavFor: thumbnailSettingsRef.current,
    };

    var thumbnailSettings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: true,
        asNavFor: settings.current,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    }
    const images = [
        imageProps?.featuredImage,
        ...(Array.isArray(imageProps?.galleryImages) ? imageProps?.galleryImages : [])
    ];
    const goto = (index) => {
        if(settingsRef.current && thumbnailSettingsRef.current){
            settingsRef.current.slickGoTo(index);
            thumbnailSettingsRef.current.slickGoTo(index);
        }
    }
    return(
        <>
            <div className='productModalImageContainer'>
                <Slider {...settings} className='main-slider' ref={settingsRef}>
                    {images?.map((image, index)  => (
                        <div className="item productImage" key={index}>
                            <InnerImageZoom
                                src={image}
                                zoomSrc={image}
                                zoomScale={1.1}
                                zoomType="hover"
                                alt={`slide ${index+1}`}
                            />
                        </div>
                    ))}
                </Slider>
                <Slider {...thumbnailSettings} className='thumbnail-slider' ref={thumbnailSettingsRef}>
                    {images?.map((image, index) => (
                        <div className="item" key={index}>
                            <img src={image} alt={`Slide ${index+1}`} className='w-100' onClick={()=>goto(index)}/>
                        </div>
                    ))}
                </Slider>
            </div> 
        </>
    )
}
export default ProductImageContainer;