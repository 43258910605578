import Dialog from '@mui/material/Dialog';
import {AiOutlineClose} from 'react-icons/ai';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { useContext } from 'react';

import {cityContext} from "../../App";
import ProductImageContainer from '../ProductImageContainer';
import WhatsappButton from '../WhatsappButton';
import PhoneButton from '../PhoneButton';

const ProductModal =()=>{
    
const context = useContext(cityContext);
const product = context.selectedProduct || {};

    return(
        <>
            <Dialog open={true} className='productModal' onClose={()=>context.setisOpenProductModal(false)}>
                <div className='productModalInside'>
                    <Button className='modalClose' onClick={()=>context.setisOpenProductModal(false)}>
                        <span className='modalCloseIcon'>
                            <AiOutlineClose />
                        </span>        
                    </Button>
                    <div className='header'>
                        <h4 className='mb-0'>{product.name}</h4>
                        <div className='d-flex align-items-center pt-3 pb-3'>
                            <div className='d-flex align-items-center border-right pr-3'>
                                <span>BRANDS: </span>
                                <span className='ml-2'>{product.brand}</span>
                            </div>
                            <div className='d-flex align-items-center border-right pr-3 pl-3'>
                                <Rating name='read-only' value={product.rating} size='small' precision={0.5} />
                                <span className='ml-2'>{product.numReviews} Reviews</span>
                            </div>
                            <div className='d-flex align-items-center pl-3'>
                                <span>SKU: </span>
                                <span className='ml-2'>{product.sku}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row mt-2'>
                        <div className='col-md-5'>
                            <ProductImageContainer 
                                featuredImage = {product.featuredImage}
                                galleryImages = {product.gallery}
                            /> 
                        </div>
                        <div className='col-md-7 productModalInformationContainer'>
                            {/*<div className='d-flex align-items-center'>
                                <span className='oldprice'>13.99 <TbCurrencyDirham /></span>
                                <span className='ml-2 newprice text-danger'>11.99 <TbCurrencyDirham /></span>
                            </div> */}
                            <span className='stock'>
                                in stock
                            </span>
                            <p className='mt-3 shortDescription' dangerouslySetInnerHTML={{ __html: product.shortDescription }}></p>
                            
                            {/*<div className='mt-3 addToCartBox d-flex align-items-center'>
                                <Link><Button onClick={()=>{productQuantity!==1? setProductQuantity(productQuantity -1): setProductQuantity(productQuantity)}}><AiOutlineMinus /></Button></Link>
                                <p className='ml-3 mb-0 quantity'>{productQuantity}</p>
                                <Link className='ml-3'><Button onClick={()=>setProductQuantity(productQuantity+1)}><AiOutlinePlus /></Button></Link>
                                <Link className='ml-4'><Button className='addToCartBtn'>Add to Cart</Button></Link>
                            </div>*/}
                            <hr />
                            <div className='icons mt-3 d-flex align-items-center mb-0'>
                                <h3 className='mr-3'>Contact: </h3>
                                <WhatsappButton 
                                    from='modal'
                                    productName = {product.name}
                                    productSKU = {product.sku}
                                    productURL = {product.url}
                                />
                                <PhoneButton 
                                    from='modal'
                                />
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default ProductModal;