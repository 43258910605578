import { Button } from "@mui/material";
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating';
import { LiaPenFancySolid } from "react-icons/lia";
import { LuPencilLine } from "react-icons/lu";
import TextField from '@mui/material/TextField';
import { CgProfile } from "react-icons/cg";
import { IoMailOutline } from "react-icons/io5";


const ProductDetailTabs = (props) => {

    const [activeTabIndex, setactiveTabIndex] = useState('0');
    const [reviewContainer, setReviewContainer] = useState('0');

    const setTabIndex =(index)=>{
        setactiveTabIndex(index);
    }

    return(
        <>
            <div className="productDetailTabs">
                <div className="TabsTitle">
                    <Button key={0} onClick={()=>setTabIndex('0')} className={activeTabIndex==='0'? 'active':''}>Description</Button>
                    <Button key={1} onClick={()=>setTabIndex('1')} className={activeTabIndex==='1'? 'active':''}>Additional Information</Button>
                    <Button key={2} onClick={()=>setTabIndex('2')} className={activeTabIndex==='2'? 'active':''}>Reviews ({props.numReviews})</Button>
                </div>
                <div className="TabsItem mt-4">
                    <div className={`TabsItemDescription ${activeTabIndex === '0'? 'active':''}`} dangerouslySetInnerHTML={{ __html: props.longDescription }}>
                        
                    </div>
                    <div className={`TabsItemSpecification ${activeTabIndex === '1'? 'active':''}`}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Attribute</TableCell>
                                        <TableCell>Variation</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.attributes.map((row,index)=>(
                                        <TableRow key={index}>
                                            <TableCell>{row.attributeName}</TableCell>
                                            <TableCell>{row.variations}</TableCell>
                                        </TableRow>
                                    ))}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className={`TabsItemReview ${activeTabIndex === '2'? 'active':''} w-100`}>
                        <div className="d-flex align-items-center w-100">
                            <div className='d-flex align-items-center pr-3 pl-3 rating'>
                                <Rating name='read-only' className="ratingIcons" value={props.rating} readOnly size='medium' precision={0.5}/>
                                <span className='ml-2 ratingCount'>({props.numReviews} Customer Reviews)</span>
                            </div>
                            <Button className="writeReview d-flex align-items-center ml-auto pr-3 pl-3" onClick={reviewContainer==='0'? ()=>setReviewContainer('1'):()=>setReviewContainer('0')}><LiaPenFancySolid className="mr-3"/> Write a Review</Button>
                            
                        </div>
                        <div className={`d-flex writeReviewContainer ${reviewContainer==='1'?'visible':''}`}>
                            <hr className="w-100"/>
                            <p className="heading">Add a review</p>
                            <p className="text">Your email address will not be published. Required fields are marked *</p>
                            <p className="text">Your rating *</p>
                            <Rating name='read-only' className="ratingIcons" size='medium' precision={0.5}/>
                            <div className="review">
                                <div>
                                    <LuPencilLine className="mr-3"/>Review *
                                </div>
                                <TextField id="standard-basic" multiline variant="standard" />
                            </div>
                            <div className="nameEmail mt-5">
                                <div className="name">
                                    <div>
                                        <CgProfile className="mr-3"/>Name *
                                    </div>
                                    <TextField id="standard-basic" multiline variant="standard" />
                                </div>
                                <div className="email">
                                    <div>
                                        <IoMailOutline className="mr-3"/>Email *
                                    </div>
                                    <TextField id="standard-basic" multiline variant="standard" />
                                </div>
                            </div>
                            <Button className="submit mt-3">Submit</Button>
                        </div>
                        <h5 className="mt-4">{props.numReviews} Reviews for {props.name}</h5>
                        <hr />
                        <div className="comment mt-4">
                            <div className="commentImage">
                                <CgProfile />
                            </div>
                            <div className="commentUserDetails ml-5">
                                <h6>Jessica Carter</h6>
                                <p>March 15, 2023</p>
                                <Rating name='read-only' className="ratingIcons" value={5} readOnly size='small' precision={0.5}/>
                                <p>I recently had some custom brochures printed, and the service was outstanding! From the initial consultation to the final delivery, everything was handled with remarkable efficiency and professionalism. The print quality was exceptional—vivid colors, sharp text, and flawless finishing. The team was incredibly helpful, addressing all my questions and ensuring that the design matched my vision perfectly. Despite my tight schedule, they managed to deliver the brochures on time, and I couldn't be happier with the result. Highly recommended for anyone needing top-notch printing services!</p>
                            </div>
                        </div>
                        <div className="comment mt-4">
                            <div className="commentImage">
                                <CgProfile />
                            </div>
                            <div className="commentUserDetails ml-5">
                                <h6>David Johnson</h6>
                                <p>October 8, 2021</p>
                                <Rating name='read-only' className="ratingIcons" value={5} readOnly size='small' precision={0.5}/>
                                <p>I recently had a batch of posters printed, and I’m thoroughly impressed with the service provided. The process was smooth from start to finish, with clear communication and a keen eye for detail. The posters turned out fantastic, with crisp images and vibrant colors that truly capture the essence of the design. The turnaround was faster than expected, which was a huge plus. The team’s professionalism and dedication to quality were evident throughout. If you’re looking for reliable printing services with excellent results, this is definitely the place to go!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductDetailTabs;