import { LuPhoneOutgoing } from "react-icons/lu";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { FaPhoneAlt  } from "react-icons/fa";
const PhoneButton = (props) => {
    const contactNumber = 'tel:+971528448523';
    return(
        <>
            {props.from === 'iconOnly'?
                <Link to={contactNumber} target="_blank"><Button><LuPhoneOutgoing /></Button></Link>:
                <Link className='mr-2' to={contactNumber} target="_blank"><Button><FaPhoneAlt /> <span className='pl-2'>Call</span></Button></Link>
            }
        </>
    )
}
export default PhoneButton;