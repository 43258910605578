import { IoHomeOutline, IoLogoWhatsapp  } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { MdLocalPhone } from "react-icons/md";
import { FaList } from "react-icons/fa";
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { cityContext } from '../../App';
const FloatingNavigation = () => {
    const context = useContext(cityContext);
    return(
        <>
            <div className="floatingNavigation">
                <div className="container w-100">
                    <div className="row">
                        <div className="col-sm-12 navigationItemsList">
                            <Link to={`/`}><Button className="navigationItem"><IoHomeOutline /><span>Home</span></Button></Link>
                            <Link to='https://wa.me/+971528448523'><Button className="navigationItem"><IoLogoWhatsapp  /><span>Whatsapp</span></Button></Link>
                            <Link to='tel:+971528448523'><Button className="navigationItem"><MdLocalPhone /><span>Contact</span></Button></Link>
                            <Link><Button className="navigationItem" onClick={()=>context.setmenuModalOpen(true)}><FaList /><span>More</span></Button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FloatingNavigation;