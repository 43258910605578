import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { FaAngleRight } from "react-icons/fa6";
import React from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import sidebanner from '../../assets/images/sidebanner.png';
import sidebanner2 from '../../assets/images/sidebanner2.png';
import HomeNewproducts from '../HomeNewproducts';
import ProductItem from '../ProductItem';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import {cityContext} from '../../App';
import { PulseLoader } from 'react-spinners';

const HomeProducts =()=>{
    const context = useContext(cityContext);
    const [loading, setloading] = useState(true);
    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowLeft
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <MdOutlineKeyboardArrowRight
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    };
    var productSliderOptions = {
        dots:false,
        infinite:false,
        speed:500,
        slidesToShow:4,
        slidesToScroll:2,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: true,
                }
            }
        ]
    }

    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [sliderKey, setSliderKey] = useState(0);
    useEffect(() => {
        const fetchFeaturedProducts = async () => {
            try {
                const response = await axios.get(`${context.apiBaseUrl}/api/product/featured`);
                
                setFeaturedProducts(response.data);
                setTimeout(() => setSliderKey(prevKey => prevKey + 1), 200);
                setloading(false);

            } catch (error) {
                console.error('Error fetching Featured Products:', error);
                setloading(false);
            }
        };
        
        fetchFeaturedProducts();
    }, [context.apiBaseUrl]);

    return(
        <>
            <section className='homeProducts'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='sticky'>
                                <div className='banner'>
                                    <img className="cursor" src={sidebanner} alt='deal1'/>
                                </div>
                                <div className='banner mt-5'>
                                    <img className="cursor" src={sidebanner2} alt='deal1'/>
                                </div>
                            </div>                        
                        </div>
                        <div className='col-md-9'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='info'>
                                            <h3 className='mb-0'>BEST SELLERS</h3>
                                            <p className='text-light mb-0'>Do not miss the current offers until the end of march</p>
                                        </div>
                                        <div className='moreProducts ml-auto'>
                                            <Link to={'./cat/featured'}><Button>View All<FaAngleRight className='ml-auto pl-2 pb-1'/></Button></Link>
                                        </div>
                                    </div>
                                    <div className='product_row w-100 mt-4'>
                                        {loading?
                                            <div className='loaderWrapper-homeAllCategory'>
                                                <PulseLoader color='#378087' loading={loading}/>
                                            </div>:
                                            <Slider {...productSliderOptions} key={sliderKey}>
                                                {featuredProducts.map((product)=>(
                                                    <ProductItem 
                                                        key={product._id}
                                                        name={product.name}
                                                        shortDescription={product.shortDescription}
                                                        title={product.title}
                                                        url={product.url}
                                                        metaKeywords={product.metaKeywords}
                                                        sku={product.sku}
                                                        featuredImage={product.featuredImage}
                                                        gallery={product.gallery}
                                                        rating={product.rating}
                                                        numReviews={product.numReviews}
                                                        brand={product.brand}
                                                    />
                                                ))}
                                            </Slider>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <HomeNewproducts />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}
export default HomeProducts;