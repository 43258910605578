
import HomeAllCategory from '../../Components/HomeAllCategory';
import HomeBanner from '../../Components/HomeBanner';
import HomeExploreallproducts from '../../Components/HomeExploreallproducts';
import HomeNewsletter from '../../Components/HomeNewsletter';
import HomeProducts from '../../Components/HomeProducts';
import { useEffect } from 'react';
const Home = () =>{
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `Home - Creative Prints`;
        document.querySelector('meta[name="description"]').setAttribute("content", 'Creative Connect Advertising Description');
        document.querySelector('meta[name="keywords"]').setAttribute("content", 'Creative Connect Advertising Keywords');
    }, []);
    return (
        <>
            <HomeBanner />
            <HomeAllCategory />
            <HomeProducts />
            <HomeNewsletter />
            <HomeExploreallproducts />
            
        </>
    )
}
export default Home;