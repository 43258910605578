import { Link } from 'react-router-dom';
import logo from '../../../assets/images/websitelogo.webp';
import Drawer from '@mui/material/Drawer';
import { AiOutlineClose } from 'react-icons/ai';
import Button from '@mui/material/Button';
import { useContext, useState } from 'react';
import { cityContext } from "../../../App";
import { IoIosArrowDown } from "react-icons/io";
import { CiLocationOn, CiFacebook, CiInstagram } from "react-icons/ci";
import { BiSupport } from "react-icons/bi";
import HeaderSearch from '../HeaderSearch';

const MobileMenuDrawer = () => {
  const { parentCategories, childCategories, setmenuModalOpen } = useContext(cityContext);
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleCategory = (index) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderCategories = (categories) => {
    return categories.map((category, index) => {
      const childCategoriesForParent = childCategories.filter(
        (childCategory) => childCategory.category._id.toString() === category._id.toString()
      );
      return (
        <li key={category._id}>
          <Link to={`cat/${category.url}`} onClick={() => setmenuModalOpen(false)}>
            {category.name}
          </Link>
          <Link className='categoryIcon' onClick={expandedCategories[index] ? () => toggleCategory(index) : () => toggleCategory(index)}>
            {childCategoriesForParent.length > 0 && <IoIosArrowDown />}
          </Link>
          {childCategoriesForParent.length > 0 && (
            <ul className={expandedCategories[index] ? 'active' : ''}>
              {childCategoriesForParent.map((childCategory) => (
                <li key={childCategory._id}>
                  <Link to={`cat/${childCategory.url}`} onClick={() => setmenuModalOpen(false)}>
                    {childCategory.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <div className="mobileMenu">
      <Drawer open={true} className='mobileMenuDrawer'>
        <div className='menuDrawerInside' onClose={() => setmenuModalOpen(false)}>
          <div className='logoWrapper d-flex align-items-center col-sm-6'>
            <Link to={'./'}><img src={logo} alt='Site Logo'></img></Link>
          </div>
          <Button className='drawerClose col-sm-6' onClick={() => setmenuModalOpen(false)}>
            <span className='drawerCloseIcon'>
              <AiOutlineClose />
            </span>
          </Button>
        </div>
        <div className='searchbar mt-3 mb-3'>
          <HeaderSearch />
        </div>
        <div className='menuList mt-3 mb-3'>
          <ul>
            {renderCategories(parentCategories)}
          </ul>
        </div>
        <div className='menuFooter mt-auto mb-3'>
          <div className='menuContact'>
            <ul>
              <li><CiLocationOn />Our Location</li>
              <li><Link to='tel:+971528448523'><BiSupport />+971 52 844 8523</Link></li>
            </ul>
          </div>
          <div className='menuFollowUs mt-3'>
            <h4>Follow Us</h4>
            <div className='menuFollowUsIcons'>
              <Link to='https://www.facebook.com/profile.php?id=61560195310434' target='_blank'><CiFacebook /></Link>
              <Link to='https://www.instagram.com/zeekayprints/' target='_blank'><CiInstagram /></Link>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default MobileMenuDrawer;
