import { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import { GoSearch } from "react-icons/go";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { RingLoader   } from 'react-spinners';
import { useContext } from 'react';
import { cityContext } from '../../../App';

const HeaderSearch = () => {
    const [searchInput, setSearchInput] = useState('');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchResultDiv, setsearchResultDiv] = useState('');

    const context = useContext(cityContext);

    const handleFocusIn = (event) => {
        const value = event.target.value;
        if(value!==''){
            setsearchResultDiv('active');
        }else{
            setsearchResultDiv('');
        }
    }
    const handleFocusOut = () => {
        setTimeout(() => {
            setsearchResultDiv('');
        }, 300);
    }
    const fetchProducts = useCallback(async (query) => {
        try {
            const response = await axios.get(`${context.apiBaseUrl}/api/product/searchProducts`, {
                params: { name: query } 
            });
            if (response.data.message) {
                setError(response.data.message);
                setProducts([]);
            } else {
                setProducts(response.data.data);
                setError('');
            }
        } catch (err) {
            setError('Failed to fetch products');
            setProducts([]);
        } finally {
            setLoading(false);
        }
    },[context.apiBaseUrl]);

    useEffect(() => {
        if (searchInput.trim()) {
            setLoading(true); 
            const timer = setTimeout(() => {
                fetchProducts(searchInput);
            }, 1000);

            return () => {
                clearTimeout(timer); 
                setLoading(false);
            };
        } else {
            setProducts([]); 
            setError(''); 
            setLoading(false); 
        }
    }, [searchInput, fetchProducts]);



    const handleInputChange = (event) => {
        const value = event.target.value;
        handleFocusIn(event);
        setProducts([]);
        setError('');
        setSearchInput(value);
        if (value.trim() === '') {
            setProducts([]);
            setError('');
            setLoading(false);
        }
    };

    const shortenTextWithReadMore = (text) => {
        const wordLimit = 12;
        const words = text.split(' ');

        if (words.length > wordLimit) {
            const shortenedText = words.slice(0, wordLimit).join(' ');
            return `${shortenedText} ...`;
        }

        return text;
    };

    return (
        <div className="headerSearch ml-3 mr-3">
            <input
                type="text"
                placeholder='Search for Products'
                value={searchInput}
                onChange={handleInputChange}
                onFocus={handleFocusIn}
                onBlur={handleFocusOut}
            />
            <Button>
                <GoSearch />
            </Button>
            <div className={`searchResult ${searchResultDiv}`}>
                {loading && (
                    <div className="loader">
                        <RingLoader color="#378087" />
                    </div>
                )}
                {error && <p className='error'>{error}</p>}
                <div className='resultedProducts'>
                    {products.map(product => (
                        <div key={product._id} className='resultedProductsItem'>
                            <img
                                className='resultedProductimg'
                                src={product.featuredImage}
                                alt='productImg'
                            />
                            <div className='resultedProductinfo'>
                                <Link className='resultedProductProductName' to={`/product/${product.url}`} onClick={()=>context.setmenuModalOpen(false)}>{product.name}</Link>
                                <p
                                    className='resultedProductShortDescription'
                                    dangerouslySetInnerHTML={{ __html: shortenTextWithReadMore(product.shortDescription,product.url) }}
                                >
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <hr />
                <div className='viewAllProducts'>
                    <Link to="/cat/all-products" onClick={()=>context.setmenuModalOpen(false)}>View all Products</Link>
                </div>
            </div>
        </div>
    );
};

export default HeaderSearch;
