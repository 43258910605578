import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from "../../Components/Sidebar";
import { FaAngleRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import ProductList from "../../Components/ProductList";
import { cityContext } from '../../App';
import SidebarFilterDrawer from '../../Components/SidebarFilterDrawer';
import axios from 'axios';

const Listing = () => {
    const { categoryUrl } = useParams();
    const [selectedCategory, setSelectedCategory] = useState(categoryUrl || null);
    const { sidebarFilter, apiBaseUrl } = useContext(cityContext);
    const [parentCategories, setParentCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                console.log(apiBaseUrl);
                const response = await axios.get(`${apiBaseUrl}/api/category`);
                const parents = [];
                const children = [];
                response.data.forEach((category) => {
                    if (category.category === null) {
                        parents.push(category);
                    } else {
                        children.push(category);
                    }
                });
                
                setParentCategories(parents);
                setChildCategories(children);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        
        fetchCategories();
    }, [apiBaseUrl]);

    useEffect(() => {
        if (categoryUrl) {
            setSelectedCategory(categoryUrl);
        }
        window.scrollTo(0, 0);
    }, [categoryUrl]);

    const handleCategoryChange = (categoryUrl) => {
        setSelectedCategory(categoryUrl);
    };

    const formatCategoryName = (name) => {
        return name
            .replace(/-/g, ' ') // Replace hyphens with spaces
            .toLowerCase() // Convert to lowercase
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
    };

    return (
        <>
            <section className="product_listing_page">
                <div className="w-100 productListingBanner">
                    <Link><h1>Shop</h1></Link>
                    <p className="d-flex align-items-center">
                        <Link to={`/`}>Home</Link>
                        <FaAngleRight className="ml-2 mr-2" /> 
                        <span>{formatCategoryName(selectedCategory)}</span>
                    </p>
                </div>
                <div className="container mt-4 mb-4">
                    <div className="productListing d-flex">
                        {sidebarFilter ? (
                            <SidebarFilterDrawer 
                                onCategoryChange={handleCategoryChange}
                                parentCategories={parentCategories}
                                childCategories={childCategories}
                            />
                        ) : (
                            <Sidebar onCategoryChange={handleCategoryChange} />
                        )}
                        <div className="content_right">
                            <ProductList category={selectedCategory} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Listing;
