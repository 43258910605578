import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { LuLayoutList } from "react-icons/lu";
import { BsGrid, BsGrid3X3Gap } from "react-icons/bs";
import { TfiLayoutGrid4 } from "react-icons/tfi";
import { IoIosArrowDown } from "react-icons/io";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ProductItem from '../ProductItem';
import Pagination from '@mui/material/Pagination';
import { FiFilter } from "react-icons/fi";
import { PulseLoader } from 'react-spinners';
import { cityContext } from '../../App';
import axios from 'axios';

const ProductList = ({ category }) => {
    const context = useContext(cityContext);
    const [products, setProducts] = useState([]);
    const [productView, setProductView] = useState(() => {
        const width = window.innerWidth;
        if (width < 1280 && width > 768) {
            return 'three'; 
        } else if (width < 768) {
            return 'two'; 
        } else {
            return 'four'; 
        }
    });
    const [productsPerPage, setProductsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        if (category) {
            const fetchProducts = async () => {
                try {
                    setLoading(true); // Start loading
                    const response = await axios.get(`${context.apiBaseUrl}/api/product/categoryProducts/${category}`);
                    setProducts(response.data);
                } catch (error) {
                    console.error('Error fetching products:', error);
                } finally {
                    setLoading(false); // Stop loading
                }
            };
            const fetchCategory = async () => {
                try {
                    const response = await axios.get(`${context.apiBaseUrl}/api/category/url/${category}`);
                    document.title = `${response.data.name} - Creative Prints`;
                    document.querySelector('meta[name="description"]').setAttribute("content", response.data.metaDescription);
                    document.querySelector('meta[name="keywords"]').setAttribute("content", response.data.metaKeywords);
                } catch (error) {
                    console.error('Error fetching category:', error);
                }
            };
            fetchCategory();
            fetchProducts();
        }
    }, [category, context.apiBaseUrl]);

    const clickProductView = (value) => {
        setProductView(value);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openDropDown = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        const value = parseInt(event.target.getAttribute('value'));
        if (value) {
            setProductsPerPage(value);
            setCurrentPage(1); // Reset to first page
        }
        setAnchorEl(null);
    };

    useEffect(() => {
        setEqualHeights();
        window.addEventListener('resize', setEqualHeights);
        return () => {
            window.removeEventListener('resize', setEqualHeights);
        };
    }, []);

    function setEqualHeights() {
        const containers = document.querySelectorAll('.productItem .info h4');
        let maxHeight = 0;

        containers.forEach((container) => {
            container.style.height = 'auto';
        });

        containers.forEach((container) => {
            if (container.offsetHeight > maxHeight) {
                maxHeight = container.offsetHeight;
            }
        });

        containers.forEach((container) => {
            container.style.height = `${maxHeight}px`;
        });
    }

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
    const pageCount = Math.ceil(products.length / productsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <> 
            <div className="showBy d-flex align-items-center w-100">
                <div className='d-flex btn-Wrapper align-items-center'>
                    <Button onClick={() => clickProductView('one')}> 
                        <LuLayoutList className={productView === 'one' ? 'active' : ''}/> 
                    </Button>
                    <Button onClick={() => clickProductView('two')}> 
                        <BsGrid className={productView === 'two' ? 'active' : ''}/>
                    </Button>
                    <Button onClick={() => clickProductView('three')}> 
                        <BsGrid3X3Gap className={productView === 'three' ? 'active' : ''}/>
                    </Button>
                    <Button onClick={() => clickProductView('four')}> 
                        <TfiLayoutGrid4 className={productView === 'four' ? 'active' : ''}/>
                    </Button>
                </div>
                <div className='ml-auto showBySidebar'>
                    <Button onClick={() => context.setsidebarFilter(true)}> 
                        <span className='pr-2'>Filter By</span><FiFilter />
                    </Button>
                </div>
                <div className='ml-auto showByFilter'>
                    <Button onClick={handleClick}>
                        <span className='pr-2'>Show</span> {productsPerPage} <IoIosArrowDown />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openDropDown}
                        onClose={handleClose}
                        className={'showByFilterDropdown'}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem className='showByProductsTotal' onClick={handleClose} value='10'>10</MenuItem>
                        <MenuItem onClick={handleClose} value='20'>20</MenuItem>
                        <MenuItem onClick={handleClose} value='30'>30</MenuItem>
                        <MenuItem onClick={handleClose} value='40'>40</MenuItem>
                        <MenuItem onClick={handleClose} value='50'>50</MenuItem>
                    </Menu>
                </div>
            </div>
            {loading ? (
                <div className='loaderWrapper-productList'>
                    <PulseLoader color='#378087' loading={loading}/>
                </div>
            ) : (
                <div className='productListing'>
                    {currentProducts.length > 0 ? (
                        currentProducts.map((product) => (
                            <ProductItem 
                                itemView={productView}
                                key={product._id}
                                name={product.name}
                                shortDescription={product.shortDescription}
                                title={product.title}
                                url={product.url}
                                metaKeywords={product.metaKeywords}
                                sku={product.sku}
                                featuredImage={product.featuredImage}
                                gallery={product.gallery}
                                rating={product.rating}
                                numReviews={product.numReviews}
                                brand={product.brand}
                            />
                        ))
                    ) : (
                        <p>No products found for the selected category.</p>
                    )}
                </div>
            )}
            {!loading && (
                <div className='d-flex align-items-center justify-content-center mt-5'>
                    <Pagination 
                        className='productListingPagination' 
                        count={pageCount} 
                        page={currentPage} 
                        size='large' 
                        onChange={handlePageChange}
                    />
                </div>
            )}
        </>
    );
};

export default ProductList;
